<template>
  <main class="container">
    <div class="header h3 mt-2">
          Estación Central / Asignar Almacenes
        <button class="btn-2 float-end" @click="showNewModal=true"><i class="fas fa-plus me-2"/> Asignar Almacén</button>
    </div>

    <!-- ASSIGN MODAL -->
    <b-modal id="modal-1" title="Agregar Almacen" class="p-3" centered v-model="showNewModal" hide-footer>
        <form @submit.prevent="postData()" class="mx-3">
            <label class="text-green-2">Almacén:</label>
            <select required class="form-select bg-white border-secondary mb-4" v-model="warehouseId">
                <option :value="undefined" disabled>* seleccione</option>
                <option :value="item.id" v-for="item in availableItems" :key="item.id" >{{item.name}}</option>
            </select>

            <button class="btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2" type="button" @click="showNewModal=false">Cencelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>


    <!-- DETAILS -->
    <div class="row mt-2 mx-1 " 
         style="min-height:75vh; background:rgba(0,0,0,0.5);">
        
        <div class="col">            
            <b-table
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(acciones)="data">
                    <div>
                        <button @click="deleteItem(data.item)" class="btn-1 me-5" style="min-width:40px;;"><i class="fas fa-trash"></i></button>
                    </div>
                </template>

            </b-table>

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
    </div>
  </main>
</template>

<script>
export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},
data(){
    return {
        items:[
            // {id:"01", name: "Vehículos"},
            // {id:"02", name: "Covid"},
            // {id:"03", name: "Uniformes"},
        ],
        
        //almacenes que no han sido asignados
        availableItems: [
            // {id:"04", name: "Consignación"},
            // {id:"05", name: "Estructural"},
        ],

        fields: [
          { key: "id", label:"Código"},
          { key: "name", label:"Nombre"},
          { key: "acciones", tdClass:"text-end", thClass:"text-end pe-5"},          
        ],
        perPage: 3,
        currentPage: 1,

        newItem:{},
        showNewModal:false,
        table: 'warehouseStations',
        subStationId: 0,
        warehouseId:0,
        warehouses: []
        
    }
},
async mounted(){
    this.subStationId = this.$route.params.id;
    await this.getWarehouses()
    await this.getData();
},
methods:{
    async getWarehouses(){
        try {
            let res = await this.$store.dispatch('get', {path: 'warehouses/getAll'});
            //console.log(res)
            if (res.length >0){
                this.warehouses = res
            }
        } catch (error) {
            this.warehouses = []
            console.error('error', error)
        }
    },
    async getData(){
        try {
            let res = await this.$store.dispatch('get', {path: this.table + '/getAllBysubStationId/' + this.subStationId });
            //console.log(res)
            if (res.length >0){
                this.items = res
                let itemsTmp = this.warehouses
                for await(let element of this.items){
                    itemsTmp = itemsTmp.filter(x => x.id != element.warehouseId)
                }
                this.availableItems = itemsTmp
            }
        } catch (error) {
            this.items = []
            console.error('error', error)
        }
    },
        async postData(){
            try {
                //console.log('seleccionado post ', this.newItem.id)
                //console.log(this.warehouseId)
                let newItem = {
                    subStationId: this.subStationId,
                    warehouseId: this.warehouseId
                }
                let res = await this.$store.dispatch('post', {path: this.table + '/new', data: newItem});
                //console.log(res)
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    //this.items = res
                    this.showNewModal=false
                    this.getData()
                    this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                }
            } catch (error) {
                this.items = []
                console.error('error', error)
            }
        },
        async deleteItem(item){
            const {isConfirmed} = await this.$swal({
                title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
                icon:"question",
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar',
                reverseButtons: true

            })
            if(isConfirmed){
                try {
                    let res = await this.$store.dispatch('delete', {path: this.table + '/delete/' + item.id});
                    if (res.error){
                        this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                    }
                    else{
                        this.getData()
                        this.$swal({title:"Aviso", text:"Eliminado correctamente", icon:"success"})
                    }
                } catch (error) {
                    this.items = []
                    console.error('error--->', error)
                }                    
            }
        }
}
}
</script>

<style>

</style>